import { Controller } from "@hotwired/stimulus"
import { hide, show, hideShallowModeModal, showShallowModeModal } from "../../utils"

// Connects to data-controller="workflows--unsaved-task-form-modal"
export default class extends Controller {
  static targets = [
    "modalWrapper",
    "modalDiscardChangesButton",
    "form"
  ]
  modalWrapperTarget: HTMLDivElement
  hasModalWrapperTarget: boolean
  modalDiscardChangesButtonTarget: HTMLAnchorElement
  formTarget: HTMLFormElement

  previousSelectedTaskId: string = null

  connect() {
    document.addEventListener("WorkflowsTaskForm:submit", this.onFormSubmit.bind(this))
  }

  disconnect() {
    document.removeEventListener("WorkflowsTaskForm:submit", this.onFormSubmit.bind(this))
  }

  onFormSubmit(e: CustomEvent): void {
    const { previousSelectedTaskId } = e.detail
    this.previousSelectedTaskId = previousSelectedTaskId

    if (this.formTarget.checkValidity()) {
      this.showUnsavedChangesModal(e)
    } else {
      // Wait a bit before sending the event, so ReactFlow has time to update
      // the selected node before reverting it back to the previous one
      setTimeout(() => {
        document.dispatchEvent(
          new CustomEvent("WorkflowSidebar:onFormValidationFailed", {
            detail: { previousSelectedTaskId: this.previousSelectedTaskId },
          }),
        )
      }, 100)
    }
  }

  showUnsavedChangesModal(e: CustomEvent): void {
    if (!this.hasModalWrapperTarget) {
      return
    }
    const { redirectUrl, _targetTaskId } = e.detail
    this.modalDiscardChangesButtonTarget.href = redirectUrl
    showShallowModeModal(this.modalWrapperTarget, this.application)
  }

  hideUnsavedChangesModal(e): void {
    e.preventDefault()
    e.stopPropagation()

    if (!this.hasModalWrapperTarget) {
      return
    }

    document.dispatchEvent(
      new CustomEvent("WorkflowSidebar:onCloseUnsavedChangesModal", {
        detail: { previousSelectedTaskId: this.previousSelectedTaskId },
      }),
    )

    this.modalDiscardChangesButtonTarget.href = "#"

    hideShallowModeModal(this.modalWrapperTarget, this.application)
  }
}
