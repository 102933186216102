import { Controller } from "@hotwired/stimulus"
import { show } from "../utils"

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ["loader"]

  loaderTarget: HTMLElement

  showLoader(): void {
    show(this.loaderTarget)
  }
}
